import request from "@/utils/request";

// 日语APP助词列表
export function AuxiliaryList(params) {
    return request({
        url: '/admin/korean_v3/auxiliaryList',
        method: 'get',
        params
    })
}

// 删除日语APP助词
export function AuxiliaryDel(data) {
    return request({
        url: '/admin/korean_v3/auxiliaryDel',
        method: 'delete',
        data
    })
}

// 添加日语APP助词
export function AuxiliaryAdd(data) {
    return request({
        url: '/admin/korean_v3/auxiliaryAdd',
        method: 'post',
        data
    })
}

// 编辑日语APP课程
export function AuxiliaryEdit(data) {
    return request({
        url: '/admin/korean_v3/auxiliaryEdit',
        method: 'put',
        data
    })
}

// 助词上传COS签名
export function getAuxiliaryCosToken(params) {
    return request({
        url: '/admin/korean_v3/getAuxiliaryCosToken',
        method: 'get',
        params
    })
}

// 日语APP助词类型
export function AuxiliaryType(params) {
    return request({
        url: '/admin/korean_v3/auxiliaryType',
        method: 'get',
        params
    })
}

// 获取日语APP助词介绍视频
export function AuxiliaryVideo(params) {
    return request({
        url: '/admin/korean_v3/auxiliaryVideo',
        method: 'get',
        params
    })
}

// 设置日语APP助词介绍视频
export function setAuxiliaryVideo(data) {
    return request({
        url: '/admin/korean_v3/auxiliaryVideo',
        method: 'post',
        data
    })
}

// 日语APP助词练习题列表
export function AuxiliarySubjectList(params) {
    return request({
        url: '/admin/korean_v3/auxiliarySubjectList',
        method: 'get',
        params
    })
}

// 添加日语APP助词练习题
export function AuxiliarySubjectAdd(data) {
    return request({
        url: '/admin/korean_v3/auxiliarySubjectAdd',
        method: 'post',
        data
    })
}

// 编辑日语APP助词练习题
export function AuxiliarySubjectEdit(data) {
    return request({
        url: '/admin/korean_v3/auxiliarySubjectEdit',
        method: 'put',
        data
    })
}

// 删除日语APP助词练习题
export function AuxiliarySubjectDel(data) {
    return request({
        url: '/admin/korean_v3/auxiliarySubjectDel',
        method: 'delete',
        data
    })
}

